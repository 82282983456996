import { Icons } from '../components/Icon/types'

export type Country = {
  name: string
  flag: Icons
}

export enum Backgrounds {
  glow = 'glow.jpg',
  hair = 'hair.jpg',
  skin = 'skin.jpg',
  glow2 = 'glow2.jpg',
}
