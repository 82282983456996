const values = {
  mobile: 450,
  tablet: 768,
  laptop: 1280,
  desktop: 1440,
}
const up = (val: keyof typeof values) => `@media (min-width: ${values?.[val]}px)`
const down = (val: keyof typeof values) => `@media (max-width: ${values?.[val]}px)`

const breakpoints = {
  values,
  up,
  down,
}

export default breakpoints
