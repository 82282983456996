import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import DefaultLayout from '../layouts/DefaultLayout'
import { RouteType } from '../types/routes'
import Page404 from '../pages/Page404'

import { defaultLayoutRoutes } from './routes'

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(({ component: Component, guard, children, path }, index: number) => {
    const Guard = guard || React.Fragment

    return children ? (
      children.map((element, index: number) => {
        const ChildGuard = element.guard || React.Fragment
        const ElementComponent = element.component || React.Fragment

        return (
          <Route
            key={index}
            path={element.path}
            element={
              <Layout>
                <Guard>
                  <ChildGuard>
                    <ElementComponent />
                  </ChildGuard>
                </Guard>
              </Layout>
            }
          />
        )
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        element={
          <Layout>
            <Guard>
              <Component />
            </Guard>
          </Layout>
        }
      />
    ) : null
  })

const RoutesComponents = () => (
  <Router>
    <Routes>
      {childRoutes(DefaultLayout, defaultLayoutRoutes)}
      <Route path="*" element={<Page404 />} />
    </Routes>
  </Router>
)

export default RoutesComponents
