import fonts from './fonts'
import breakpoints from './breakpoints'
import colors from './colors'
import textStyles from './textStyles'

const theme = {
  colors,
  fonts,
  breakpoints,
  textStyles,
}

export default theme
