import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.ITCAvantGardeW1GBold}
    font-size: 1.6rem;
  `,
  titleH2: `
    ${fonts.ITCAvantGardeW1GBold}
    font-size: 1.6rem;
  `,
  text: `
    ${fonts.ITCAvantGardeW1GMedium}
    font-size: 1.6rem;
  `,
}

export default textStyles
