import React, { FC, memo } from 'react'

import * as icons from './icons'
import * as SC from './styled'
import { Icons } from './types'

export type IconProps = {
  className?: string
  icon: Icons
  color?: string
  onClick?: React.MouseEventHandler<HTMLOrSVGElement>
}

const Icon: FC<IconProps> = ({ className, icon, color = 'inherit', onClick }) => {
  const iconProps: any = icons[icon]
  const { viewBox, id } = iconProps

  if (!iconProps) {
    console.warn('Icon missing :', icon)
    return null
  }

  return (
    <SC.Container
      className={className}
      as={onClick ? 'button' : 'span'}
      color={color}
      onClick={onClick}
    >
      <SC.Svg viewBox={viewBox}>
        <use xlinkHref={`#${id}`} href={`#${id}`} />
      </SC.Svg>
    </SC.Container>
  )
}

export default memo(Icon)
