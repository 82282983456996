import styled from 'styled-components'

import ActionButton from '../../components/ActionButton'

export const Background = styled.div`
  background: linear-gradient(0deg, rgba(210, 205, 231, 0.1), rgba(210, 205, 231, 0.1)),
    url('/images/backgrounds/glow.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  flex-grow: 1;
  padding: 2rem;
`

export const Title = styled.p`
  font-size: 25px;
`

export const Cta = styled(ActionButton)`
  margin-top: 3rem;
  width: auto;
`
