import React from 'react'

interface GuestGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  return children
}

export default GuestGuard
