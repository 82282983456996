import gql from 'graphql-tag'

export const login = {
  query: gql`
    query AuthLogin($email: String!, $password: String!) {
      login(email: $email, password: $pasword)
    }
  `,
  // TODO: use AuthLoginQuery generated type
  transformer: (response: any) => {
    return response
  },
}
