import gql from 'graphql-tag'

export const refreshToken = {
  mutation: gql`
    mutation AuthRefreshToken {
      refreshToken
    }
  `,
  // TODO: use AuthRefreshTokenMutation generated type
  transformer: (response: any) => {
    return response.refreshToken
  },
}
