import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import DefaultLayout, { DefaultLayoutProps } from '../../layouts/DefaultLayout'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NotFoundTemplateProps = {
  layoutPtops?: DefaultLayoutProps
  title?: string
  cta?: ActionButtonProps
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = ({ layoutPtops, title, cta }) => {
  return (
    <DefaultLayout {...layoutPtops}>
      <SC.Background />
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Cta {...cta} />
      </SC.Content>
    </DefaultLayout>
  )
}

export default NotFoundTemplate
