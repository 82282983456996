import React from 'react'
import { RouteProps } from 'react-router-dom'

import HomeTemplate from '../../templates/Landing'
import { landingTemplateMocks } from '../../templates/Landing/mocks'

const HairPage: React.FC<RouteProps> = () => {
  return <HomeTemplate {...landingTemplateMocks.hair} />
}

export default HairPage
