import styled, { css } from 'styled-components'
import PulseLoader from 'react-spinners/PulseLoader'

import Icon from '../Icon'
import LinkC from '../Link'

import type { ActionButtonProps } from './index'

export const Link = styled(LinkC)`
  display: inline-flex;
  text-decoration: none;

  > * {
    width: 100%;
  }
`

export const Button = styled.button<{
  disabled?: boolean
  $variant: ActionButtonProps['variant']
  $isLoading?: boolean
}>`
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 3rem;
  min-width: 12rem;
  justify-content: center;
  transition: all 0.2s ease-out;
  cursor: pointer;

  ${(props) => props.theme.textStyles.text}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
  ${(props) => {
    switch (props.$variant) {
      case 'primary':
        return css`
          border: 1px solid ${(props) => props.theme.colors.pureBlack};
          color: ${(props) => props.theme.colors.pureWhite};
          background-color: ${(props) => props.theme.colors.pureBlack};
        `
      case 'secondary':
        return css`
          border: 1px solid ${(props) => props.theme.colors.pureBlack};
          color: ${(props) => props.theme.colors.pureBlack};
          background-color: ${(props) => props.theme.colors.pureWhite};
        `
    }
  }}
  &:hover {
    ${(props) => {
      if (!props.disabled) {
        switch (props.$variant) {
          case 'primary':
            return css`
              color: ${(props) => props.theme.colors.pureBlack};
              background-color: ${(props) => props.theme.colors.pureWhite};
            `
          case 'secondary':
            return css`
              color: ${(props) => props.theme.colors.pureWhite};
              background-color: ${(props) => props.theme.colors.pureBlack};
            `
        }
      }
    }}
  }
`

export const IconIn = styled(Icon)<{ iconSide?: string }>`
  position: relative;
  top: -0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: ${(props) => (props.iconSide === 'right' ? '1rem' : '0')};
  margin-right: ${(props) => (props.iconSide === 'left' ? '1rem' : '0')};
`

export const Spinner = styled(PulseLoader)``
