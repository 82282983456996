import React, { FC } from 'react'

import colors from '../../theme/colors'
import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type ActionButtonProps = {
  className?: string
  link?: LinkProps
  variant?: 'primary' | 'secondary'
  type?: 'submit' | 'button'
  text?: string
  icon?: Icons
  iconSide?: 'left' | 'right'
  disabled?: boolean
  isLoading?: boolean
  onClick?: (e: any) => void
}

const ActionButton: FC<ActionButtonProps> = ({
  className,
  link,
  variant = 'primary',
  type = 'button',
  text,
  icon,
  iconSide = 'left',
  disabled,
  children,
  onClick,
  isLoading,
}) => {
  const renderIcon = icon && <SC.IconIn iconSide={iconSide} icon={icon} />

  const renderButton = (
    <SC.Button
      className={!link?.href ? className : ''}
      type={type}
      disabled={disabled}
      onClick={onClick}
      $variant={variant}
      $isLoading={isLoading}
    >
      {!isLoading ? (
        <>
          {icon && iconSide === 'left' && renderIcon}
          {children || text}
          {icon && iconSide === 'right' && renderIcon}
        </>
      ) : (
        <SC.Spinner color={colors.pureWhite} size={10} />
      )}
    </SC.Button>
  )

  return link?.href ? (
    <SC.Link className={className} {...link} href={link?.href} onClick={onClick}>
      {renderButton}
    </SC.Link>
  ) : (
    renderButton
  )
}

export default ActionButton
