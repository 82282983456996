export enum Icons {
  check = 'check',
  chevronDown = 'chevronDown',
  close = 'close',
  down = 'down',
  refresh = 'refresh',
  // flags
  arabEmirates = 'arabEmirates',
  bahrain = 'bahrain',
  czechRepublic = 'czechRepublic',
  denmark = 'denmark',
  france = 'france',
  germany = 'germany',
  greece = 'greece',
  italy = 'italy',
  kuwait = 'kuwait',
  oman = 'oman',
  poland = 'poland',
  portugal = 'portugal',
  qatar = 'qatar',
  romania = 'romania',
  saudiArabia = 'saudiArabia',
  spain = 'spain',
  sweden = 'sweden',
  switzerland = 'switzerland',
  turkey = 'turkey',
  unitedKingdom = 'unitedKingdom',
}
