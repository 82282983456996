import { generatePath } from 'react-router-dom'
import { Params } from 'react-router'

export const router = <S extends string>(path: S, params?: Params<S>) => {
  return generatePath(path, params)
}

export const routesPath = {
  glow: '/best-skin-ever',
  skin: '/good-skin-care',
  hair: '/hair-care',
  glow2: '/best-skin-ever-matte',
}
