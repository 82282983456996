import React from 'react'
import { RouteProps } from 'react-router-dom'

import HomeTemplate from '../../templates/Landing'
import { landingTemplateMocks } from '../../templates/Landing/mocks'

const GlowPage2: React.FC<RouteProps> = () => {
  return <HomeTemplate {...landingTemplateMocks.glow2} />
}

export default GlowPage2
