import styled from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Flag = styled(Icon)`
  width: 3.4rem;
  height: 2.6rem;
  margin-right: 2.4rem;
`

export const Name = styled.p`
  ${(props) => props.theme.textStyles.text}
  color: ${(props) => props.theme.colors.pureBlack};
  line-height: 1;
  margin-top: 0.3rem;
`
