import gql from 'graphql-tag'

import { UserFragment } from '../../../fragments'

export const me = {
  query: gql`
    query UserMe {
      me {
        ...UserFragment
      }
    }
    ${UserFragment}
  `,
  // TODO: use UserMeQuery generated type
  transformer: (response: any) => {
    return response.me
  },
}
