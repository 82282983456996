import { REHYDRATE } from 'redux-persist'
import { all, call, fork, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects'

import { actions, selectors } from '..'
import AuthSagas from '../auth/sagas'

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)
    yield call(AuthSagas.onInit) // must be first auth call to refresh token if needed
    yield fork(AppSagas.loadInitialData) // can be forked

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *loadInitialData() {
    yield all([])
  }

  static *listeners() {
    yield all([takeLeading(REHYDRATE, this.init)])
  }
}
