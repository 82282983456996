import styled, { css } from 'styled-components'
import { Link as DomLinkC } from 'react-router-dom'

export const Link = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

export const DomLink = styled(DomLinkC)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

export const Span = styled.span<{ $isButton?: boolean }>`
  ${({ $isButton }) =>
    $isButton &&
    css`
      cursor: pointer;
    `}
`
