import { RouteType } from '../types/routes'
import GuestGuard from '../components/GuestGuard'
import AuthGuard from '../components/AuthGuard'
import GlowPage from '../pages/Glow'
import HairPage from '../pages/Hair'
import SkinPage from '../pages/Skin'
import GlowPage2 from '../pages/Glow2'

import { routesPath } from './index'

const publicRoutes: RouteType = {
  path: '',
  component: null,
  guard: GuestGuard,
  children: [
    {
      path: routesPath.glow,
      name: 'Glow',
      component: GlowPage,
    },
    {
      path: routesPath.hair,
      name: 'Hair',
      component: HairPage,
    },
    {
      path: routesPath.skin,
      name: 'Skin',
      component: SkinPage,
    },
    {
      path: routesPath.glow2,
      name: 'Glow2',
      component: GlowPage2,
    },
  ],
}

const privateRoutes: RouteType = {
  path: '',
  component: null,
  guard: AuthGuard,
  children: [],
}

export const defaultLayoutRoutes = [publicRoutes, privateRoutes]
