import { all, put, takeEvery, delay } from 'redux-saga/effects'

import { actions } from '..'
import { ApiResponse } from '../api/types/state'
import { services } from '../../graphql'
import { setAuthCookie } from '../../helpers/CookieHelpers'

export default class AuthSagas {
  static *onInit() {
    // const cookie = getAuthCookie()
    // console.log('AUTH : init with cookie', cookie)
    // if (cookie) {
    //   yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
    //   yield put(actions.auth.setToken(cookie?.token))
    // }
    // const isConnected: SagaReturnType<typeof selectors.auth.isConnected> = yield select(
    //   selectors.auth.isConnected
    // )
    // console.log('AUTH : is connected', isConnected)
    // if (isConnected) {
    //   const rs: ApiResponse<typeof services.user.queries.me> = yield call(
    //     ApiSagas.query,
    //     services.user.queries.me
    //   )
    //   console.log('AUTH : get me', rs)
    //   if (!rs?.errors) {
    //     yield put(actions.auth.setUser(rs?.data))
    //   } else {
    //     yield put(actions.auth.resetAuth())
    //   }
    // }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static *onLoginRequest({ payload }: ReturnType<typeof actions.auth.loginRequest>) {
    // const response: ApiResponse<typeof services.auth.queries.login> = yield call(
    //   ApiSagas.query,
    //   services.auth.queries.login,
    //   {
    //     email: payload.email,
    //     password: payload.password,
    //   }
    // )

    // fake API response
    const isGood = payload.name === 'root' && payload.password === 'root'
    const response: ApiResponse<typeof services.auth.queries.login> = {
      data: isGood,
      errors: !isGood ? [] : null,
    }

    // fake delay
    yield delay(1000)

    if (response.errors) {
      yield put(actions.auth.loginError(response.errors))
    }

    if (response.data) {
      setAuthCookie({ token: 'mock_token', impersonate: false })
      yield put(actions.auth.loginSuccess())
    }
  }

  static *listeners() {
    yield all([takeEvery(actions.auth.loginRequest, this.onLoginRequest)])
  }
}
