import React, { FC } from 'react'

import CountryLink, { CountryLinkProps } from '../../components/CountryLink'
import DefaultLayout from '../../layouts/DefaultLayout'
import { Backgrounds, Country } from '../../types'

import * as SC from './styled'

export type LandingTemplateProps = {
  className?: string
  title: string
  countries: CountryLinkProps[]
  background: Backgrounds
}

const LandingTemplate: FC<LandingTemplateProps> = (props) => {
  const { title, countries, background } = props

  return (
    <DefaultLayout>
      <SC.Background $background={background} />
      <SC.Content>
        <SC.Logo
          src={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
          srcSet={`${process.env.PUBLIC_URL}/images/logo/logo.png 247w, ${process.env.PUBLIC_URL}/images/logo/logo-desktop.png 328w`}
          alt="logo"
        />
        <SC.Title>{title}</SC.Title>
        <SC.Countries>
          {countries.map((country: Country, i: number) => (
            <CountryLink key={i} {...country} />
          ))}
        </SC.Countries>
      </SC.Content>
    </DefaultLayout>
  )
}

export default LandingTemplate
