import styled, { css } from 'styled-components'

export const Container = styled.div<{ color: string }>`
  background: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  color: ${(props) => props.color};
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`

export const Svg = styled.svg`
  width: inherit;
  height: inherit;
`
