import React, { FC } from 'react'

import { Country } from '../../types'

import * as SC from './styled'

export type CountryLinkProps = {
  className?: string
  href?: string
} & Country

const CountryLink: FC<CountryLinkProps> = (props) => {
  const { className, href, name, flag } = props

  return (
    <SC.Container className={className} href={href}>
      <SC.Flag icon={flag} />
      <SC.Name>{name}</SC.Name>
    </SC.Container>
  )
}

export default CountryLink
