import styled from 'styled-components'

import { Backgrounds } from '../../types'

export const Background = styled.div<{
  $background: string
}>`
  background: ${({ $background }) =>
    $background === Backgrounds.glow || $background === Backgrounds.glow2
      ? `linear-gradient(0deg, rgba(210, 205, 231, 0.1), rgba(210, 205, 231, 0.1)), url('/images/backgrounds/${$background}')}`
      : $background === Backgrounds.hair
      ? `linear-gradient(0deg, rgba(195, 230, 233, 0.2), rgba(195, 230, 233, 0.2)), url('/images/backgrounds/${$background}')}`
      : `linear-gradient(0deg, rgba(200, 211, 218, 0.1), rgba(200, 211, 218, 0.1)), url('/images/backgrounds/${$background}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1;
  ${(props) => props.theme.breakpoints.down('mobile')} {
    background-position-x: 'center';
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 3.6rem;
  z-index: 2;
  ${(props) => props.theme.breakpoints.up('tablet')} {
    padding: 3rem 10.5rem;
  }
`

export const Logo = styled.img`
  width: 100%;
  max-width: 25rem;
  display: flex;
  align-self: center;
  ${(props) => props.theme.breakpoints.up('tablet')} {
    max-width: 33rem;
  }
`

export const Title = styled.h1`
  ${(props) => props.theme.textStyles.titleH1}
  color: ${(props) => props.theme.colors.pureBlack};
  margin: 5rem 0 3.5rem 0;
  ${(props) => props.theme.breakpoints.up('tablet')} {
    margin: 10rem 0 3.6rem 0;
    font-weight: 600;
    font-size: 2rem;
  }
`

export const Countries = styled.div`
  display: flex;
  flex-direction: column;
  & > a {
    margin-bottom: 3.2rem;
  }
  ${(props) => props.theme.breakpoints.up('tablet')} {
    max-height: 60rem;
    flex-wrap: wrap;
    align-content: flex-start;
    & > a {
      max-width: 26rem;
      margin-right: 15rem;
    }
  }
`
