import { all, fork } from 'redux-saga/effects'

import ApiSagas from './api/sagas'
import AppSagas from './app/sagas'
import AuthSagas from './auth/sagas'

function* allListeners() {
  yield all([ApiSagas.listeners(), AppSagas.listeners(), AuthSagas.listeners()])
}

export default function* rootSaga() {
  yield fork(allListeners)
}
