import gql from 'graphql-tag'

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    token
    user_type
    first_name
    last_name
    email
    phone
  }
`
