import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectors } from '../redux'
import { router, routesPath } from '../router'

interface AuthGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const isConnected = useSelector(selectors.auth.isConnected)

  if (!isConnected) {
    return <Navigate to={router(routesPath.glow)} />
  }

  return children
}

export default AuthGuard
