import React, { FC, useMemo } from 'react'

import * as SC from './styled'

export type LinkProps = {
  className?: string
  href?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  rel?: string
  onClick?: (e?: any) => void
}

const Link: FC<LinkProps> = (props) => {
  const { className, children, href, target, title, role, tabIndex, rel, onClick } = props

  const isInternalLink = useMemo(() => href?.indexOf('http') !== 0, [href])

  return (
    <>
      {href && isInternalLink && (
        <SC.DomLink
          className={className}
          to={href}
          {...(tabIndex && { tabIndex })}
          {...(target && { target })}
          {...(title && { title })}
          {...(role && { role })}
          {...(rel && { rel })}
          {...(onClick && { onClick })}
        >
          {children}
        </SC.DomLink>
      )}

      {href && !isInternalLink && (
        <SC.Link
          className={className}
          href={href}
          {...(tabIndex && { tabIndex })}
          {...(target && { target })}
          {...(title && { title })}
          {...(role && { role })}
          {...(rel && { rel })}
          {...(onClick && { onClick })}
        >
          {children}
        </SC.Link>
      )}

      {!href && (
        <SC.Span className={className} $isButton={onClick !== undefined} onClick={onClick}>
          {children}
        </SC.Span>
      )}
    </>
  )
}

export default Link
