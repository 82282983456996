import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type DefaultLayoutProps = {
  className?: string
  header?: React.ReactNode
  footer?: React.ReactNode
}

const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  className,
  header = null,
  footer = null,
}) => {
  return (
    <SC.Container className={cx(className)}>
      {header}
      {children}
      {footer}
    </SC.Container>
  )
}

export default DefaultLayout
