import { Icons } from '../../components/Icon/types'
import { Backgrounds } from '../../types'

import { LandingTemplateProps } from './index'

const countriesGlow = [
  {
    name: 'Arab Emirates',
    flag: Icons.arabEmirates,
    href: 'https://bitly.big-boss.sh/3DZO1vi',
  },
  {
    name: 'Saudi Arabia',
    flag: Icons.saudiArabia,
    href: 'https://bitly.big-boss.sh/3UPctGN',
  },
  {
    name: 'Bahrain',
    flag: Icons.bahrain,
    href: 'https://bitly.big-boss.sh/3fRQksm',
  },
  {
    name: 'Czech Republic',
    flag: Icons.czechRepublic,
    href: 'https://bitly.big-boss.sh/3A3yGsz',
  },
  {
    name: 'Denmark',
    flag: Icons.denmark,
    href: 'https://bitly.big-boss.sh/3DXd5Df',
  },
  {
    name: 'France',
    flag: Icons.france,
    href: 'https://bitly.big-boss.sh/3th6TB6',
  },
  {
    name: 'Germany',
    flag: Icons.germany,
    href: 'https://bitly.big-boss.sh/3TrY5TJ',
  },
  {
    name: 'Greece',
    flag: Icons.greece,
    href: 'https://bitly.big-boss.sh/3EmYkLB',
  },
  {
    name: 'Italy',
    flag: Icons.italy,
    href: 'https://bitly.big-boss.sh/3fS2Zvv',
  },
  {
    name: 'Kuwait',
    flag: Icons.kuwait,
    href: 'https://bitly.big-boss.sh/3NSAOZT',
  },
  {
    name: 'Oman',
    flag: Icons.oman,
    href: 'https://bitly.big-boss.sh/3WPqPbR',
  },
  {
    name: 'Poland',
    flag: Icons.poland,
    href: 'https://bitly.big-boss.sh/3NUOaEY',
  },
  {
    name: 'Portugal',
    flag: Icons.portugal,
    href: 'https://bitly.big-boss.sh/3UHDSu2',
  },
  {
    name: 'Qatar',
    flag: Icons.qatar,
    href: 'https://bitly.big-boss.sh/3thIM5h',
  },
  {
    name: 'Romania',
    flag: Icons.romania,
    href: 'https://bitly.big-boss.sh/3tkaheI',
  },
  {
    name: 'Spain',
    flag: Icons.spain,
    href: 'https://bitly.big-boss.sh/3ElLc9n',
  },
  {
    name: 'Sweden',
    flag: Icons.sweden,
    href: 'https://bitly.big-boss.sh/3UoYKGQ',
  },
  {
    name: 'Switzerland',
    flag: Icons.switzerland,
    href: 'https://bitly.big-boss.sh/3A5Yjc9',
  },
  {
    name: 'Turkey',
    flag: Icons.turkey,
    href: 'https://bitly.big-boss.sh/3UJIJuF',
  },
  {
    name: 'United Kingdom',
    flag: Icons.unitedKingdom,
    href: 'https://bitly.big-boss.sh/3EmZ0jK',
  },
]

const countriesGlow2 = [
  {
    name: 'Arab Emirates',
    flag: Icons.arabEmirates,
    href: 'https://bitly.big-boss.sh/3qs8eHz',
  },
  {
    name: 'Saudi Arabia',
    flag: Icons.saudiArabia,
    href: 'https://bitly.big-boss.sh/47HwVR8',
  },
  {
    name: 'Bahrain',
    flag: Icons.bahrain,
    href: 'https://bitly.big-boss.sh/45tHx3U',
  },
  {
    name: 'Czech Republic',
    flag: Icons.czechRepublic,
    href: 'https://bitly.big-boss.sh/3sh2gJV',
  },
  {
    name: 'Denmark',
    flag: Icons.denmark,
    href: 'https://bitly.big-boss.sh/3sk29xi',
  },
  {
    name: 'France',
    flag: Icons.france,
    href: 'https://bitly.big-boss.sh/3OG8iwE',
  },
  {
    name: 'Germany',
    flag: Icons.germany,
    href: 'https://bitly.big-boss.sh/47to19E',
  },
  {
    name: 'Greece',
    flag: Icons.greece,
    href: 'https://bitly.big-boss.sh/3Pctw5u',
  },
  {
    name: 'Italy',
    flag: Icons.italy,
    href: 'https://bitly.big-boss.sh/3OT6Mr8',
  },
  {
    name: 'Kuwait',
    flag: Icons.kuwait,
    href: 'https://bitly.big-boss.sh/3QDTwrM',
  },
  {
    name: 'Oman',
    flag: Icons.oman,
    href: 'https://bitly.big-boss.sh/3s80vii',
  },
  {
    name: 'Poland',
    flag: Icons.poland,
    href: 'https://bitly.big-boss.sh/3sbYlyf',
  },
  {
    name: 'Portugal',
    flag: Icons.portugal,
    href: 'https://bitly.big-boss.sh/3OOZvYc',
  },
  {
    name: 'Qatar',
    flag: Icons.qatar,
    href: 'https://bitly.big-boss.sh/3OxEuBa',
  },
  {
    name: 'Romania',
    flag: Icons.romania,
    href: 'https://bitly.big-boss.sh/3qJlIih',
  },
  {
    name: 'Spain',
    flag: Icons.spain,
    href: 'https://bitly.big-boss.sh/3YSJAMM',
  },
  {
    name: 'Sweden',
    flag: Icons.sweden,
    href: 'https://bitly.big-boss.sh/3KTYTiw',
  },
  {
    name: 'Switzerland',
    flag: Icons.switzerland,
    href: 'https://bitly.big-boss.sh/3OCPLzQ',
  },
  {
    name: 'Turkey',
    flag: Icons.turkey,
    href: 'https://bitly.big-boss.sh/3PcvDWU',
  },
  {
    name: 'United Kingdom',
    flag: Icons.unitedKingdom,
    href: 'https://bitly.big-boss.sh/45wcFA5',
  },
]

const countriesHair = [
  {
    name: 'Arab Emirates',
    flag: Icons.arabEmirates,
    href: 'https://bitly.big-boss.sh/3A3T2BS',
  },
  {
    name: 'Saudi Arabia',
    flag: Icons.saudiArabia,
    href: 'https://bitly.big-boss.sh/3tl8NAQ',
  },
  {
    name: 'Bahrain',
    flag: Icons.bahrain,
    href: 'https://bitly.big-boss.sh/3A3EXVh',
  },
  {
    name: 'Czech Republic',
    flag: Icons.czechRepublic,
    href: 'https://bitly.big-boss.sh/3hm2RVi',
  },
  {
    name: 'Denmark',
    flag: Icons.denmark,
    href: 'https://bitly.big-boss.sh/3zZTQaP',
  },
  {
    name: 'France',
    flag: Icons.france,
    href: 'https://bitly.big-boss.sh/3P8IKVJ',
  },
  {
    name: 'Germany',
    flag: Icons.germany,
    href: 'https://bitly.big-boss.sh/3Tdv9jk',
  },
  {
    name: 'Greece',
    flag: Icons.greece,
    href: 'https://bitly.big-boss.sh/3hvrSO6',
  },
  {
    name: 'Italy',
    flag: Icons.italy,
    href: 'https://bitly.big-boss.sh/3dgsHbg',
  },
  {
    name: 'Kuwait',
    flag: Icons.kuwait,
    href: 'https://bitly.big-boss.sh/3A5KY3L',
  },
  {
    name: 'Oman',
    flag: Icons.oman,
    href: 'https://bitly.big-boss.sh/3Ej4RXo',
  },
  {
    name: 'Poland',
    flag: Icons.poland,
    href: 'https://bitly.big-boss.sh/3TlzUWN',
  },
  {
    name: 'Portugal',
    flag: Icons.portugal,
    href: 'https://bitly.big-boss.sh/3fPrY2B',
  },
  {
    name: 'Qatar',
    flag: Icons.qatar,
    href: 'https://bitly.big-boss.sh/3Uy7aMc',
  },
  {
    name: 'Romania',
    flag: Icons.romania,
    href: 'https://bitly.big-boss.sh/3fYA4pp',
  },
  {
    name: 'Spain',
    flag: Icons.spain,
    href: 'https://bitly.big-boss.sh/3tkkQy5',
  },
  {
    name: 'Sweden',
    flag: Icons.sweden,
    href: 'https://bitly.big-boss.sh/3WRZsOw',
  },
  {
    name: 'Switzerland',
    flag: Icons.switzerland,
    href: 'https://bitly.big-boss.sh/3tpNhdX',
  },
  {
    name: 'Turkey',
    flag: Icons.turkey,
    href: 'https://bitly.big-boss.sh/3NTsngS',
  },
  {
    name: 'United Kingdom',
    flag: Icons.unitedKingdom,
    href: 'https://bitly.big-boss.sh/3E5OwEn',
  },
]

const countriesSkin = [
  {
    name: 'Arab Emirates',
    flag: Icons.arabEmirates,
    href: 'https://bitly.big-boss.sh/3A5u5Gm',
  },
  {
    name: 'Saudi Arabia',
    flag: Icons.saudiArabia,
    href: 'https://bitly.big-boss.sh/3WLMp13',
  },
  {
    name: 'Bahrain',
    flag: Icons.bahrain,
    href: 'https://bitly.big-boss.sh/3TsmxUK',
  },
  {
    name: 'Czech Republic',
    flag: Icons.czechRepublic,
    href: 'https://bitly.big-boss.sh/3pXmHqU',
  },
  {
    name: 'Denmark',
    flag: Icons.denmark,
    href: 'https://bitly.big-boss.sh/3NYNTky',
  },
  {
    name: 'France',
    flag: Icons.france,
    href: 'https://bitly.big-boss.sh/3AkXAoh',
  },
  {
    name: 'Germany',
    flag: Icons.germany,
    href: 'https://bitly.big-boss.sh/3RbBaLE',
  },
  {
    name: 'Greece',
    flag: Icons.greece,
    href: 'https://bitly.big-boss.sh/3TFQhhn',
  },
  {
    name: 'Italy',
    flag: Icons.italy,
    href: 'https://bitly.big-boss.sh/3UrYPcy',
  },
  {
    name: 'Kuwait',
    flag: Icons.kuwait,
    href: 'https://bitly.big-boss.sh/3UKE6AF',
  },
  {
    name: 'Oman',
    flag: Icons.oman,
    href: 'https://bitly.big-boss.sh/3UJRNjb',
  },
  {
    name: 'Poland',
    flag: Icons.poland,
    href: 'https://bitly.big-boss.sh/3NSCJ0x',
  },
  {
    name: 'Portugal',
    flag: Icons.portugal,
    href: 'https://bitly.big-boss.sh/3G4FzO1',
  },
  {
    name: 'Qatar',
    flag: Icons.qatar,
    href: 'https://bitly.big-boss.sh/3hqYGrn',
  },
  {
    name: 'Romania',
    flag: Icons.romania,
    href: 'https://bitly.big-boss.sh/3htHIIU',
  },
  {
    name: 'Spain',
    flag: Icons.spain,
    href: 'https://bitly.big-boss.sh/3AMyM8B',
  },
  {
    name: 'Sweden',
    flag: Icons.sweden,
    href: 'https://bitly.big-boss.sh/3hqSzmV',
  },
  {
    name: 'Switzerland',
    flag: Icons.switzerland,
    href: 'https://bitly.big-boss.sh/3En5mQv',
  },
  {
    name: 'Turkey',
    flag: Icons.turkey,
    href: 'https://bitly.big-boss.sh/3Tglzw9',
  },
  {
    name: 'United Kingdom',
    flag: Icons.unitedKingdom,
    href: 'https://bitly.big-boss.sh/3g3X0DY',
  },
]

export const glow: LandingTemplateProps = {
  title: 'Choose your country :',
  countries: countriesGlow,
  background: Backgrounds.glow,
}

export const hair: LandingTemplateProps = {
  ...glow,
  countries: countriesHair,
  background: Backgrounds.hair,
}

export const skin: LandingTemplateProps = {
  ...glow,
  countries: countriesSkin,
  background: Backgrounds.skin,
}

export const glow2: LandingTemplateProps = {
  ...glow,
  countries: countriesGlow2,
  background: Backgrounds.glow2,
}

export const landingTemplateMocks = {
  glow,
  hair,
  skin,
  glow2,
}
