import { all, call, put, select, take } from 'redux-saga/effects'
import { differenceInMinutes } from 'date-fns'
import { NotifiableError } from '@bugsnag/js'

import client, { contentfulClient, defaultConfig, persistedClient } from '../../graphql/Client'
import { actions, selectors } from '..'
import bugsnagClient from '../../helpers/BugsnagHelpers'
import * as configuration from '../../configuration'
import { transformErrors } from '../../helpers/GraphqlHelpers'
import { services } from '../../graphql'

import {
  ApiResponse,
  GraphqlQueryVariables,
  QueryType,
  ServiceMutation,
  ServiceQuery,
} from './types/state'

const DEBUG = configuration.api.DEBUG
const log = DEBUG ? console.log : () => null

export type ApiTransformer = (data: any) => any

export default class ApiSagas {
  static *getHeaders(checkToken = true): any {
    const headers: Headers = yield select(selectors.api.headers)
    let token = yield select(selectors.auth.token)

    if (checkToken) {
      token = yield call(ApiSagas.getToken)
    }

    return {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    }
  }

  static *query(service: ServiceQuery, variables: GraphqlQueryVariables | null = null) {
    const headers: Headers = yield call(ApiSagas.getHeaders)
    const response: ApiResponse = yield ApiSagas.call(client.query, service, variables, headers)
    return response
  }

  static *mutate(service: ServiceMutation, variables: GraphqlQueryVariables | null = null) {
    const headers: Headers = yield call(ApiSagas.getHeaders)
    const response: ApiResponse = yield ApiSagas.call(client.mutate, service, variables, headers)
    return response
  }

  static *persistQuery(query: QueryType | any, variables: GraphqlQueryVariables | null = null) {
    const headers: Headers = yield call(ApiSagas.getHeaders, false)
    const response: ApiResponse = yield ApiSagas.call(
      persistedClient.query,
      query?.query ? query : { query },
      variables,
      { ...headers, Authorization: undefined } as Headers
    )
    return response
  }

  static *call(
    method:
      | typeof client.query
      | typeof client.mutate
      | typeof persistedClient.query
      | typeof contentfulClient.query,
    service: ServiceQuery | ServiceMutation,
    variables: GraphqlQueryVariables | null = null,
    headers: Headers
  ) {
    let result: ApiResponse

    try {
      // @ts-ignore
      result = yield call(method, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
            ...headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas:`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as NotifiableError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors)
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    )

    return resultTransformed
  }

  static *contentfulQuery(service: ServiceQuery, variables: GraphqlQueryVariables | null = null) {
    let result: ApiResponse

    try {
      // @ts-ignore
      result = yield call(contentfulClient.query, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas:`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as NotifiableError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors)
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    )

    return resultTransformed
  }

  static *transform(result: ApiResponse, transformer: ApiTransformer) {
    if (!result.data || !transformer) {
      return result
    }

    const data: ApiTransformer = yield call(transformer, result.data as any)

    return { ...result, data } as ApiResponse
  }

  static *checkTokenExpire() {
    const storeToken: string | null = yield select(selectors.auth.token)
    const jwt: ReturnType<typeof selectors.auth.jwt> = yield select(selectors.auth.jwt)
    const expirationDate: number | undefined = jwt?.exp

    if (!expirationDate || !storeToken) {
      return storeToken
    }

    const expires = new Date(expirationDate * 1000)
    const diff = -differenceInMinutes(new Date(), expires)
    log(`Api: Token expires at ${expirationDate} (${new Date(expirationDate * 1000)}) (${diff})`)

    const refreshingToken: boolean | null = yield select(selectors.api.refreshing)
    if (refreshingToken) {
      log('Api: Token already refreshing')
      yield take(actions.api.setRefreshing.type)
      const newStoreToken: string | null = yield select(selectors.auth.token)
      log('Api: Token refreshing complete', newStoreToken)
      return newStoreToken
    }

    if (diff >= 5) {
      return storeToken
    }

    yield put(actions.api.setRefreshing(true))
    log('Api: token needs refreshing')
    const headers: Headers = yield call(ApiSagas.getHeaders, false)

    const result: ApiResponse<typeof services.user.mutations.refreshToken> = yield call(
      ApiSagas.call,
      client.mutate,
      {
        ...services.user.mutations.refreshToken,
        context: {
          headers,
        },
      },
      {},
      headers
    )

    if (result.errors) {
      log('Api: refresh token error', transformErrors(result.errors))
      yield put(actions.auth.resetAuth())
      return null
    }

    const token = result?.data ?? null
    if (!token) {
      log('Api: refresh token error')
      yield put(actions.auth.resetAuth())
      return null
    }

    log('Api: refresh token success', result?.data)
    yield put(actions.auth.setToken(token))
    yield put(actions.api.setRefreshing(false))

    return token
  }

  static *getToken() {
    const token: ReturnType<typeof selectors.auth.token> = yield call(ApiSagas.checkTokenExpire)
    return token
  }

  static *listeners() {
    yield all([
      //
    ])
  }
}
